<template>
  <button type="button" class="absolute right-0 top-0 px-4 h-[44px]" aria-label="Close  popup" @click="$emit('close')">
    <i class="icon77 icon77-exit" />
  </button>

  <div :id="CurrentStep" class="online-appointments flex flex-col-reverse" data-cy="appointment-form">
    <div v-if="CurrentStep == 'firstStep'" class="chatNow">
      <div class="text-center p-4 md:p-6 lg:p-12">
        <h3 class="heading-5 mb-4">
          {{ $t('appoint-form-chatnow-text1', 'Have a Quick Question?') }}
        </h3>

        <p class="body-14-aa mb-3">
          {{ $t('appoint-form-chatnow-text2', 'Our consultants are happy to help 24/7 through live chat.') }}
        </p>

        <button type="button" class="cta-black max-w-[220px] m-auto" @click="openChat2">
          {{ $t('appoint-form-chatnow-btn', 'Chat Now') }}
        </button>
      </div>
    </div>
    <div class="grid77 gap-0">
      <div class="md:col-span-5 lg:col-span-6 hidden md:block text-center">
        <img
          v-if="CurrentStep != 'fourthStep' || CurrentShowroom.IsOpen"
          :src="`/images/img-2023/BookAppImages/${bookAppImage}`"
          class="pr-3 mx-4 my-6"
        />
        <img v-else :src="`/assets/Images/Showroom/${CurrentShowroom.City}-coming-soon.png`" />
      </div>

      <div class="col-span-12 md:col-span-7 lg:col-span-6 p-4 md:p-6 lg:p-12">
        <template v-if="CurrentStep == 'firstStep'">
          <online-appointments-steps-step1 @next-step="setStep('secondStep')" />
        </template>

        <template v-if="CurrentStep == 'secondStep'">
          <online-appointments-steps-step2 @next-step="selectShowroom" @prev-step="setStep('firstStep')" />
        </template>

        <template v-if="CurrentStep == 'thirdStep'">
          <online-appointments-steps-step3
            :disabledDates="disabledDates"
            :selectedInterest="selectedInterest"
            :selectedDate="selectedDate"
            :preferedTime="preferedTime"
            @next-step="selectDate"
            @prev-step="setStep('secondStep')"
          />
        </template>

        <template v-if="CurrentStep == 'fourthStep'">
          <online-appointments-steps-step4 @next-step="setUserData" @prev-step="setStep('thirdStep')" />
        </template>

        <template v-if="CurrentStep == 'lastStep'">
          <online-appointments-steps-step5 @next-step="setAppointment" @prev-step="setStep('fourthStep')" />
        </template>

        <template v-if="CurrentStep == 'confirmation'">
          <div id="confirmation" class="p-5 mt-5" data-cy="confirmation">
            <h3 class="heading-5 mb-3">
              {{ $t('appoint-form-confirmation-title1', { name: UserData.FirstName }, 'Thanks, {name}!') }}
            </h3>

            <h3 class="heading-5 mb-4">
              {{
                CurrentShowroom.IsOpen
                  ? $t('appoint-form-confirmation-title2', 'We’ll contact you soon to confirm your appointment.')
                  : $t('thank-you-for-contacting', 'Thank you for contacting 77 Diamonds')
              }}
            </h3>

            <p class="body-14-aa">
              {{
                $t(
                  'appt-form-confirmation-message-newer',
                  'One of our experts will email you within the next 24 hours on weekdays to confirm your booking and prepare for your upcoming consultation.',
                )
              }}
            </p>

            <p class="body-14-aa">
              {{
                $t(
                  'appoint-form-confirmation-text2',
                  'In the meantime, check out our collections for ideas or read our helpful guides about diamond and gemstone jewellery.',
                )
              }}
            </p>

            <div class="grid grid-cols-2 gap-2 lg:my-9 text-center" @click="$emit('close')">
              <NuxtLink
                id="c-collections"
                :href="translateShopAspRouteToFriendlyUrl('/ShopNew/Default.aspx?category=6')"
                class="cta-black"
              >
                {{ $t('appoint-form-confirmation-btn1', 'browse collections') }}
              </NuxtLink>
              <NuxtLinkLocale id="c-guide" href="/diamond-education.html" class="cta-black">
                {{ $t('appoint-form-confirmation-btn2', 'read guides') }}
              </NuxtLinkLocale>
            </div>

            <div class="socials my-3 flex gap-3 place-content-center">
              <a
                v-for="socialMedia in Object.values(SOCIAL_MEDIA).filter(
                  (item) => item.NAME !== SOCIAL_MEDIA.WHATSAPP.NAME,
                )"
                :key="socialMedia.NAME"
                :href="socialMedia.LINK"
                :title="socialMedia.NAME"
                :class="socialMedia.NAME.toLocaleLowerCase()"
                :data-social="socialMedia.NAME.toLocaleLowerCase()"
                data-page="Booking Confirmation Popup"
                rel="noopener"
                target="_blank"
              >
                <span class="icon77" :class="socialMedia.SPRITE_CLASS"></span>
              </a>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
  <!-- alertify replacement -->
  <ui-popup v-if="ErrorMessage" v-model="ErrorMessage" hide-footer container-class="md:w-[440px] p-0">
    <div class="flex flex-col justify-center p-8">
      <div class="text-center mb-4" v-html="ErrorMessage"></div>
      <ui-button77 variant="yellow" @click="closeAlert">{{ $t('ok', 'OK') }}</ui-button77>
    </div>
  </ui-popup>
</template>

<script setup lang="ts">
const emit = defineEmits(['close'])

import { useOnlineAppointments } from '~/store/bookAppointmentStore'
import { SOCIAL_MEDIA } from '~/constants/social'
import { openChat2 } from '~/utils/chat'

const { Showrooms } = storeToRefs(useOnlineAppointments())
const {
  setStep,
  CurrentShowroom,
  selectShowroom,
  selectDate,
  showroomNotOpened,
  selectUser,
  setAppointment,
  getShowrooms,
} = useOnlineAppointments()

const { ErrorMessage } = storeToRefs(useOnlineAppointments())
const { closeAlert } = useOnlineAppointments()
const { translateShopAspRouteToFriendlyUrl } = useAspRoutes()

const { CurrentStep, UserData } = storeToRefs(useOnlineAppointments())

const bookAppImage = computed(() => {
  if (CurrentStep.value == 'firstStep' || CurrentStep.value == 'secondStep') return 'Step-0_x1.jpg'
  else return 'Step-2-London_x1.jpg'
})

const setUserData = async () => {
  if (!CurrentShowroom.IsOpen) {
    showroomNotOpened()
  } else selectUser()
}

onMounted(async () => {
  if (!Showrooms.value) {
    await getShowrooms()
  }
})
</script>

<style lang="scss">
.modal-mask.book-appointment {
  align-items: flex-start;
  padding-top: 40px;

  .modal-wrapper {
    padding: 0 10px;

    @media screen and (max-width: 767.8px) {
      width: 100%;
    }
  }

  .icon77.icon77-chevron.-left {
    vertical-align: -5px;
  }
}

.scrollable-container {
  // @media screen and (min-width: 768px) {
  // position: absolute;
  height: 90%;
  max-height: 584px;
  width: 100%;
  overflow: auto;
  // }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 3px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.chatNow {
  background-color: #f7f1e5;
  padding: 10px;
}

#footerContacts.appointmentPopup .modal #firstStep {
  padding-top: 50px;
}

._form-group.floating {
  position: relative;

  &:not(.-sticky) ::placeholder {
    opacity: 0;
  }

  > label {
    top: 13px;
    position: absolute;
    transition: 0.2s;
    pointer-events: none;
    color: #666;
    margin: 0 16px;

    font-size: 14px;
    white-space: nowrap;
  }

  input._form-control {
    // padding: 30px 16px 16px;
    padding-top: 28px;
    padding-bottom: 14px;
  }

  select._form-control {
    padding: 14px 35px 0px 15px;

    appearance: none;
    background-image: url(/images/shop/icons/chevron-down.svg);
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: center right 15px;

    option {
      font-family: 'Nunito Sans';
    }
  }

  &.-sticky ._form-control,
  ._form-control:focus,
  select._form-control:valid,
  input._form-control:not(:placeholder-shown) {
    ~ label {
      top: 6px;
      font-size: 12px;
    }
  }

  .focused-input {
    top: 3px;
    font-size: 12px;
  }
}

._form-group ._text-danger p {
  margin: 2px 0px;
  font-size: 12px;
  color: #a9201e;
}

._form-group._form-group--error ._form-control {
  border: 1px solid #a9201e;
}

.gdprCheckboxHolder {
  label {
    display: flex;
    align-items: flex-start;
    margin: 0;
    cursor: pointer;
    padding: 0;
  }

  a {
    text-decoration: underline;
  }

  input[type='checkbox'] + label:before {
    content: '';
    // display: inline-block;
    min-width: 18px;
    height: 18px;
    border: 1px solid #0d0900;
    margin-right: 7px;
    translate: 0 5px;
  }

  input:checked + label:before {
    background-image: url(/images/shop/icons/full_tick.svg);
    background-color: #000;
    background-position: 50%;
    background-size: 19px;
    background-repeat: no-repeat;
    border-color: #000;
  }
}
</style>

<!--script>
    import Step1 from './steps/Step1';
    import Step2 from './steps/Step2';
    import Step3 from './steps/Step3';
    import Step4 from './steps/Step4';
    import Step5 from './steps/Step5';

    export default {
        components: { Step1, Step2, Step3, Step4, Step5 },

        data() {
            return {
                CurrentStep: 'firstStep',
                showrooms: [],
                currentShowroom: null,
                selectedInterest: null,
                selectedDate: null,
                preferedTime: null,
                userData: null
                // firstAvailableDate
            }
        },

        watch: {
            user() {
                debugger;
            },

            user2: {
                handler: function (newValue) {
                    this.$store.commit('updateUser', this.user);
                },
                // Tell vue to do a deep watch of entire form object to watch child items in the object
                deep: true
            },
        },

        computed: {
            ...mapState([
                'user'
            ]),

            bookAppImage() {
                if (this.CurrentStep == 'firstStep' || this.CurrentStep == 'secondStep')
                    return '/images/img-2023/BookAppImages/Step-0_x1.jpg';
                else
                    return '/images/img-2023/BookAppImages/Step-2-London_x1.jpg';
            }
        },

        methods: {
            selectShowroom ({showroom}) {
                this.currentShowroom = showroom;
                this.setStep('thirdStep');

                this.selectedDate = null;
            },

            selectDate ({selectedInterest, selectedDate, preferedTime}) {
                this.selectedInterest = selectedInterest;
                this.selectedDate = selectedDate;
                this.preferedTime = preferedTime;

                this.setStep('fourthStep');
            },

            async selectUser ({userData}) {
                this.userData = userData;

                let captchaResponse = await captcha.getToken();

                await axios
                    .post(`/WebService.asmx/SaveRequest`, {
                        codePhone: userData.PhoneCode,
                        phone: userData.PhoneNumber,
                        email: userData.Email,
                        fullName: `${userData.FirstName} ${userData.LastName}`,
                        collectionPlace: this.currentShowroom.Id,
                        appointmentType: 2, //1 = online, 2 = resential
                        addinfo: '',
                        dateAppointment: '',
                        hourAppointment: '',
                        subject: '',
                        form: 'BookAppointment',
                        futureCall: false,
                        salePrice: '',
                        currencyId: '',
                        diamondCode: '',
                        uploadedFilename: '',
                        optedIn: true,
                        captchaResponse: captchaResponse,
                        enquiryType: 1,
                        virtualMeeting: false,
                        virtualMeetingLangId: 0,
                        virtualMeetingTechId: 0,
                        startDate: '',
                        endDate: '',
                        roomId: 0,
                        productTypeId: this.selectedInterest,
                        pickedProductTypes: [this.selectedInterest],
                        appointmentDate: this.selectedDate,
                        preferedTime: this.preferedTime,
                    })
                    .then((response) => {
                        if (response) {
                            this.showrooms = response.data.d;

                            this.setStep('lastStep');
                        }
                    });


            },

            // backToStep3() {
            //     this.setStep('thirdStep');
            //     this.loadCalendar(false, new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1, 0, 0, 0));
            // }
        },

        async mounted() {
            await axios
                .post(`/WebService.asmx/GetShowroomsByUserLocation`, {})
                .then((response) => {
                    if (response) {
                        this.showrooms = response.data.d;
                    }
                });
        },

        created() {
        },
    };
</script-->
