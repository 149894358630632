<template>
  <UiLoader centered :value="loading" />
  <HeaderTopBar />
  <header ref="headerRef">
    <CheckoutHeader v-if="isCheckoutPage" />
    <Header v-else />
  </header>
  <div class="shop-new relative">
    <slot />
    <LayoutStickyActionsContainer />
  </div>
  <Footer />

  <div
    id="toast-container"
    class="fixed z-[999] inset-0 bottom-0 top-auto flex flex-col-reverse items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-end sm:justify-end"
  ></div>
  <Teleport to="#teleport-content">
    <div id="layout-bottom-sticky" class="fixed bottom-0 w-full">
      <FooterCookieBar v-if="showCookieBar" @close="showCookieBar = false" />
      <AddToBag v-if="showAddToBag" />
    </div>
  </Teleport>

  <div id="teleport-content" class="z-[999] fixed"></div>
</template>
<script setup lang="ts">
import Footer from '@/components/layout/footer'
import Header from '@/components/layout/header'
import CheckoutHeader from '@/components/layout/header/CheckoutHeader.vue'
import HeaderTopBar from '@/components/layout/header/HeaderTopBar.vue'
import { useLoaderStore } from '~/store/loaderStore'
import AddToBag from '~/components/shop/AddToBag.vue'
import FooterCookieBar from '~/components/layout/footer/FooterCookieBar.vue'

import { SHOP_ROUTES_NAMES } from '~/constants'

const { showCookieBar } = useLayout()

const router = useRouter()
const { loading } = storeToRefs(useLoaderStore())

const isCheckoutPage = computed(() => {
  return router.currentRoute.value.path === '/checkout'
})

const headerRef = ref<HTMLElement>()

const { width } = useWindowSize()

const isMobile = computed(() => width.value < 1024)

// Make header menu sticky on scroll
const handleScroll = () => {
  const menuOffsetItem = isMobile.value ? headerRef.value : document.querySelector('.NavigationItems')
  const menuOffset = menuOffsetItem?.getBoundingClientRect().top

  if (menuOffset < 0) {
    document.body.classList.add('stickyHeader')
  } else {
    document.body.classList.remove('stickyHeader')
  }
}

const route = useRoute()
const { routeName: shopRouteName } = useShopRouteName()
const showAddToBag = computed(() => {
  return (
    shopRouteName.value &&
    shopRouteName.value !== SHOP_ROUTES_NAMES.ITEM_FINISH &&
    route.query.step !== SHOP_ROUTES_NAMES.ITEM_FINISH
  )
})

onBeforeMount(() => {
  // instantly check position for pages with hash
  handleScroll()

  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style lang="scss">
header {
  min-height: 60px;
  border-bottom: 1px solid #f2f2f2;

  body.stickyHeader & {
    height: 59px;

    @media (min-width: 768px) {
      height: 86px;
    }

    @media (min-width: 1024px) {
      height: 110px;
    }
  }
}

.TopHeader {
  position: relative;
  padding: 15px 12px;

  @media (min-width: 768px) {
    padding: 25px;
  }

  @media (max-width: 1023.98px) {
    body.stickyHeader & {
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;
      background-color: white;
      width: 100%;
      border-bottom: 1px solid #e4e4e4;
    }
  }

  @media (min-width: 1024px) {
    padding: 22px 32px;

    .localizedLogo img {
      height: 40px;
    }
  }

  .localizedLogo img {
    height: 40px;
  }
}

.marketingHeader {
  min-height: 60px;
  border-bottom: 1px solid #f2f2f2;
}
</style>
